<template>
  <div :class="{'add-height': addHeight}">
    <b-row class="stac-header mx-0">
      <div class="w-100">
        <div class="lead stac-container header-container" v-if="url || routeName">

          <b-button :to="'/'" size="sm" class="header-logo">
            <div class="head-logo"></div>
            <div>
              <div class="alpha-title">Проводится</div>
              <div class="alpha-title">альфа-тестирование</div>
            </div>
          </b-button>
          <div class="link-group">
            <b-button class="header-link link-1" :to="'/'" size="sm">
              <span class="button-label prio">Браузер</span>
            </b-button>
            <b-button class="header-link link-2" :to="`${externalCatalogUrl ? externalCatalogUrl : '/'}`" size="sm">
              <span class="button-label prio">Главная</span>
            </b-button>
            <b-button class="header-link link-3" size="sm" v-b-toggle.sidebar title="Просмотреть">
              <span class="button-label prio">Каталог</span>
            </b-button>
            <b-button class="header-link link-4" size="sm" :disabled="!catalogUrl || !supportsSearch" :to="searchBrowserLink" :class="{'header-link-disabled': !catalogUrl || !supportsSearch}" :title="`${catalogUrl ? 'Просмотреть' : 'Выберите каталог на вкладке Браузер'}`">
              <span class="button-label prio">Поиск</span>
            </b-button>
            <div class="link-underline"></div>
          </div>

<!--          <div class="link-group-small">
            <b-button  class="header-link link-1" :to="'/'" size="sm">
              <span class="button-label prio">Главная</span>
            </b-button>
            <b-button v-b-toggle="'collapse-sm'" @click="toggleHeight" class="header-link menu-arrow-btn">
              <div class="menu-arrow"></div>
            </b-button>
          </div>-->

          <Share class="share-xl-sm" :title="title" :stacUrl="url" :stacVersion="stacVersion"/>

          <b-button v-b-toggle="'collapse-xs'" @click="toggleHeight" class="header-link menu-burger-btn">
            <div class="menu-burger" :class="{'menu-burger-active': addHeight}" ></div>
          </b-button>

        </div>
      </div>
    </b-row>
<!--    <b-collapse id="collapse-sm" class="menu-collapse">-->
<!--      <div class="menu-collapse-container" v-b-toggle="'collapse-sm'" @click="toggleHeight">-->
<!--        <b-button class="header-link" v-b-toggle.sidebar title="Просмотреть">-->
<!--          <span class="button-label">Каталог</span>-->
<!--        </b-button>-->
<!--        <b-button class="header-link" title="Просмотреть" :to="'/search'">-->
<!--          <span class="button-label">Поиск</span>-->
<!--        </b-button>-->
<!--      </div>-->
<!--    </b-collapse>-->

    <b-collapse id="collapse-xs" class="menu-collapse">
      <div class="menu-collapse-container">
        <div class="stac-container" v-b-toggle="'collapse-xs'" @click="toggleHeight">
          <b-button  class="header-link collapse-link" :to="'/'" size="sm">
            <span class="button-label prio">Браузер</span>
            <!--            <div class="menu-arrow-right"></div>-->
          </b-button>
          <b-button  class="header-link collapse-link" :to="`${externalCatalogUrl ? externalCatalogUrl : '/'}`" size="sm">
            <span class="button-label prio">Главная</span>
<!--            <div class="menu-arrow-right"></div>-->
          </b-button>
          <b-button class="header-link collapse-link" v-b-toggle.sidebar title="Просмотреть">
            <span class="button-label">Каталог</span>
<!--            <div class="menu-arrow-right"></div>-->
          </b-button>
          <b-button class="header-link collapse-link" :disabled="!catalogUrl" :class="{'header-link-disabled': !catalogUrl}" :title="`${catalogUrl ? 'Просмотреть' : 'Выберите каталог на вкладке Браузер'}`" :to="searchBrowserLink">
            <span class="button-label">Поиск</span>
<!--            <div class="menu-arrow-right"></div>-->
          </b-button>
        </div>
        <hr class="menu-hr"/>
        <div class="stac-container">
          <b-button class="header-link collapse-link" id="popover-link-xs" title="Источники STAC" @click="onToggleBlur">
            <div class="menu-link-img"></div>
            <span class="button-label">Данные</span>
          </b-button>
          <b-button class="header-link collapse-link" id="popover-share-xs" title="Поделиться этой страницей с остальными" @click="onToggleBlur">
            <div class="menu-share-img"></div>
            <span class="button-label">Поделиться</span>
          </b-button>
          <b-button class="header-link collapse-link" id="popover-logout-xs" title="Сменить APIKEY" :to="'/'" @click="resetApiKey">
            <div class="menu-logout-img"></div>
            <span class="button-label">Сменить APIKEY</span>
          </b-button>
        </div>
<!--        <Share class="share-xl-sx" :title="title" :stacUrl="url" :stacVersion="stacVersion" @toggle="onToggleBlur"/>-->
      </div>
    </b-collapse>

    <div class="popover-blur" id="popover-blur-xs" :class="{'popover-blur-xs-active': shown}" @click="onToggleBlur">
      <b-popover
          v-if="url" target="popover-link-xs" triggers="click blur" placement="bottom"
          container="popover-blur-xs" title="Source Data"
          @show="validate"
          customClass="popover-share"
      >
        <template v-if="stacVersion">
          <b-row class="mt-1">
            <b-col cols="3">STAC Version:</b-col>
            <b-col>{{ stacVersion }}</b-col>
          </b-row>
          <b-row v-if="canValidate" class="mb-3 mt-1">
            <b-col cols="3">Valid:</b-col>
            <b-col>
              <b-spinner v-if="valid === null" label="Validating..." small />
              <template v-else-if="valid === true">✔</template>
              <template v-else-if="valid === false">❌</template>
              <template v-else>n/a</template>
            </b-col>
          </b-row>
        </template>
        <Url id="stacUrl" :url="url" label="The STAC metdata file is located at:" />
      </b-popover>
      <b-popover target="popover-share-xs" triggers="click blur" placement="bottom" container="popover-blur-xs" title="Share" customClass="popover-share">
        <Url id="browserUrl" :url="browserUrl()" label="Share the URL of this page anywhere you like:" :open="false" />
      </b-popover>
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import StacLink from './StacLink.vue';
import { BIconArrow90degUp, BIconBook, BIconFolderSymlink, BIconSearch, BCollapse, BPopover } from "bootstrap-vue";
import STAC from '../models/stac';
import Utils from '../utils';
import URI from "urijs";
import Url from './Url.vue';

export default {
  name: 'StacHeader',
  components: {
    BIconArrow90degUp,
    BIconBook,
    BIconFolderSymlink,
    BIconSearch,
    BCollapse,
    BPopover,
    Url,
    StacLink,
    Share: () => import('../components/Share.vue')
  },
  computed: {
    ...mapState(['allowSelectCatalog', 'catalogUrl', 'data', 'url', 'title', 'valid']),
    ...mapGetters(['root', 'rootLink', 'parentLink', 'collectionLink', 'stacVersion', 'supportsSearch', 'toBrowserPath', 'externalCatalogUrl']),
    icon() {
      if (this.data instanceof STAC) {
        let icons = this.data.getIcons();
        if (icons.length > 0) {
          return icons[0];
        }
      }
      return null;
    },
    searchBrowserLink() {
      // if (!this.allowSelectCatalog) {
      //   return '/search';
      // }
      // // else if (this.supportsSearch && this.root) {
      // //   return `/search${this.root.getBrowserPath()}`;
      // // }
      // else if (this.supportsSearch && this.url) {
      //   return `/search${this.toBrowserPath(this.url)}`;
      // }
      // else {
      //   return null;
      // }

      return '/search' + this.externalCatalogUrl;
    },
    containerLink() {
      // Check two cases where this page is the root...
      if (this.catalogUrl && this.url === this.catalogUrl) {
        return null;
      }
      if (this.root) {
        if (Utils.equalUrl(this.root.getAbsoluteUrl(), this.url)) {
          return null;
        }
        else {
          return {
            href: this.root.getAbsoluteUrl(),
            rel: 'root',
            title: STAC.getDisplayTitle(this.root)
          };
        }
      }
      return this.collectionLink || this.parentLink;
    },
    canValidate() {
      if (!this.stacLint || typeof this.url !== 'string') {
        return false;
      }
      else if (Utils.size(this.privateQueryParameters) > 0) {
        // Don't expose private query parameters to externals
        return false;
      }
      else if (Array.isArray(this.stacProxyUrl)) {
        // Don't validate if a proxy has been set
        return false;
      }
      let uri = new URI(this.url);
      let host = uri.hostname().toLowerCase();
      if (host === 'localhost' || host.startsWith('127.') || host === '::1') {
        // Can't validate localhost
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      addHeight: false,
      shown: false,
      routeName: null
    }
  },
  mounted() {
    this.routeName = this.isSearchPage;
  },
  methods: {
    isSearchPage() {
      return this.$router.currentRoute.name === 'search';
    },
    toggleHeight() {
      this.addHeight = !this.addHeight;
    },
    onToggleBlur() {
      this.shown = !this.shown;
    },
    async validate() {
      await this.$store.dispatch('validate', this.url);
    },
    browserUrl() {
      return window.location.toString();
    },
    resetApiKey() {
      this.$store.commit('setApiKey', '');
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  word-break: break-word;
}

.stac-header {
  height: 80px;
  background-color: #6379A6;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
/*  @media (max-width: 1279px) {
    height: 70px;
  }*/
}

.header-logo {
  background: none;
  border-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 479px) {
    width: 81px;
  }
}

.header-logo:hover {
  background: none;
  border-color: transparent;
}

.header-link {
  padding: 0.25rem 0;
  color: white;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 101px;
  border: none;
  @media (max-width: 1279px) {
    height: 74px;
    width: 80px;
  }
}

.header-link-disabled {
  background-color: transparent !important;

  .button-label {
    color: #C9C9C9;
  }
}

.header-link:hover {
  background-color: transparent;
}

.link-1:hover ~ .link-underline {
  opacity: 1;
  left: 0;
  transition: 0.4s;
}

.link-2:hover ~ .link-underline {
  opacity: 1;
  left: calc(25% + 5px);
  transition: 0.4s;
}

.link-3:hover ~ .link-underline {
  opacity: 1;
  left: calc(50% + 10px);
  transition: 0.4s;
}

.link-4:hover ~ .link-underline {
  opacity: 1;
  left: calc(75% + 15px);
  transition: 0.4s;
}

.header-link:active, .header-logo:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.header-link:focus, .header-logo:focus {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.header-link:focus .link-underline {
  opacity: 1;
}

.header-link:active {
  background-color: rgba(32, 64, 128, 0.5) !important;
}


.link-group {
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: relative !important;
  width: 466px;
  @media (max-width: 1279px) {
    display: none;
  }
}

.link-group-small {
  display: none;
  @media (max-width: 1279px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 479px) {
    display: none;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-label {
  width: 100%;
  color: white;
  font-weight: 500;
  @media (max-width: 1279px) {
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
}

a {
  color: white !important;
}

.link-underline {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 101px;
  height: 4px;
  background-color: #ED1941;
}

.alpha-title {
  max-width: 132px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  @media (max-width: 1279px) {
    display: none;
  }
}

.head-logo {
  background-image: url("../theme/assets/img/logo_v2_white.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 68px;
  width: 235px;
  @media (max-width: 479px) {
    width: 35px;
    background-image: url("../theme/assets/img/logo_v1_white-small.svg");
  }
}

.menu-collapse {
  background-color: #6379A6;
  position: fixed !important;
  top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  width: 100%;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
  & span {
    font-weight: 400;
  }
}

.menu-arrow {
  width: 20px;
  height: 20px;
  background-image: url("../theme/assets/img/menu-arrow.svg");
}

.menu-arrow-btn {
  width: 20px;
  height: 20px;
}

.menu-collapse-container {
  min-height: 0px;

  & .btn {
    margin-left: calc(50% - 55px);
  }
}

.add-height {
  height: 100px;
}

.share-xl-sm {
  @media (max-width: 479px) {
    display: none;
  }
}

.menu-burger {
  width: 36px;
  height: 36px;
  background-image: url("../theme/assets/img/menu-burger.svg");
}

.menu-burger-btn {
  display: none;
  @media (max-width: 1279px) {
    display: flex;
    width: auto;
    height: 36px;
  }
}

.menu-burger-active {
  width: 36px;
  height: 36px;
  background-image: url("../theme/assets/img/menu-burger-active.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.menu-arrow-right {
  width: 20px;
  height: 20px;
  background-image: url("../theme/assets/img/arrow-right.svg");
}

.collapse-link {
  width: 100%;
  margin: 0 !important;
}

.popover-blur {
  @media (max-width: 1279px) {
    backdrop-filter: blur(4px);
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.5s;
    display: none;
  }
}

.popover-blur-xs-active {
  @media (max-width: 1279px) {
    display: block;
    opacity: 1;
    z-index: 2;
  }
}

.menu-link-img {
  background-image: url("../theme/assets/img/menu-link-img.svg");
  min-width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px;
  margin-right: 16px;
}

.menu-share-img {
  background-image: url("../theme/assets/img/menu-share-img.svg");
  min-width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px;
  margin-right: 16px;
}

.menu-logout-img {
  background-image: url("../theme/assets/img/logout_white.svg");
  min-width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px;
  margin-right: 16px;
}

.menu-hr {
  border-top: 1px solid #FFFFFF;
}
</style>