<template>
  <div id="stac-browser" class="mx-auto">
    <Authentication v-if="!apiKey" />
    <b-sidebar id="sidebar" shadow lazy backdrop :backdrop-variant="variant">
      <Sidebar />
    </b-sidebar>
    <!-- Header -->
    <header class="header">
      <div class="logo">{{ catalogTitle }}</div>
      <StacHeader />
    </header>
    <!-- Content (Item / Catalog) -->
    <main>
      <ErrorAlert class="global-error" v-if="globalError" v-bind="globalError" @close="hideError" />
      <router-view class="site-container stac-container"/>
    </main>
    <!-- Footer -->
    <StacFooter/>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import Vuex, { mapGetters, mapState } from 'vuex';
import getRoutes from "./router";
import getStore from "./store";

import {
  AlertPlugin, BadgePlugin, ButtonGroupPlugin, ButtonPlugin,
  CardPlugin, LayoutPlugin, SidebarPlugin, SpinnerPlugin,
  VBToggle, VBVisible } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Clipboard from 'v-clipboard';

import ErrorAlert from './components/ErrorAlert.vue';
import Sidebar from './components/Sidebar.vue';
import StacHeader from './components/StacHeader.vue';
import StacFooter from "@/components/StacFooter";

import Utils from './utils';
import URI from 'urijs';

Vue.use(Clipboard);

Vue.use(AlertPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(BadgePlugin);
Vue.use(CardPlugin);
Vue.use(LayoutPlugin);
Vue.use(SidebarPlugin);
Vue.use(SpinnerPlugin);

// For collapsibles / accordions
Vue.directive('b-toggle', VBToggle);
// Used to detect when a catalog/item becomes visible so that further data can be loaded
Vue.directive('b-visible', VBVisible);

let CONFIG;
if (typeof CONFIG_PATH === 'undefined' && typeof CONFIG_CLI === 'undefined') {
  CONFIG = require('../config');
}
else {
  CONFIG = Object.assign(require(CONFIG_PATH), CONFIG_CLI);
}

// Setup store
Vue.use(Vuex);
const store = getStore(CONFIG);

// Setup router
Vue.use(VueRouter);
const router = new VueRouter({
  mode: CONFIG.historyMode,
  base: CONFIG.pathPrefix,
  routes: getRoutes(CONFIG)
});

// Pass Config through from props to vuex
let Props = {};
let Watchers = {};
for(let key in CONFIG) {
  Props[key] = {
    default: ['object', 'function'].includes(typeof CONFIG[key]) ? () => CONFIG[key] : CONFIG[key]
  };
  Watchers[key] = {
    immediate: true,
    handler: function(newValue) {
      this.$store.commit('config', {
        [key]: newValue
      });
    }
  };
}

export default {
  name: 'StacBrowser',
  router,
  store,
  components: {
    StacFooter,
    Authentication: () => import('./components/Authentication.vue'),
    ErrorAlert,
    Sidebar,
    StacHeader,
  },
  props: {
    ...Props
  },
  data() {
    return {
      error: null,
      variant: "transparent",
    };
  },
  computed: {
    ...mapState(['title', 'doAuth', 'globalError', 'stateQueryParameters', 'apiKey', 'privateQueryParameters']),
    ...mapState({catalogUrlFromVueX: 'catalogUrl'}),
    ...mapGetters(['displayCatalogTitle']),
    browserVersion() {
      if (typeof STAC_BROWSER_VERSION !== 'undefined') {
        return STAC_BROWSER_VERSION;
      }
      else {
        return "";
      }
    },
    appStateAsParams () {
      const out = {};
      for (const [key, value] of Object.entries(this.$store.state.stateQueryParameters)) {
        if (Array.isArray(value) && value.length > 0) {
          out[`.${key}`] = value;
        }
      }
      return out;
    }
  },
  watch: {
    ...Watchers,
    title(title) {
      document.title = title;
    },
    catalogUrlFromVueX(url) {
      if (url) {
        // Load the root catalog data if not available (e.g. after page refresh or external access)
        this.$store.dispatch("load", { url, loadApi: true });
      }
    }
  },
  created() {
    // The handling of unwatch and this.$watch sequence below explictly attaches the watcher
    // for stateQueryParameters AFTER the router has navigated to the page, and AFTER 
    // the parseQuery method has been run which modifies the stateQueryParameters.
    // The updateUrlOnStateChange method causes navigation to happen with vue-router, so we
    // we don't want to happen on initial page render when the we're already at the right location
    let unwatch = null;
    this.$router.onReady(() => {
      this.parseQuery(this.$route);
      unwatch = this.$watch('stateQueryParameters', this.updateUrlOnStateChange, {deep: true});
    });

    // When we're navigating to a completely different STAC page (denoted by a different path)
    // we need to clear out out the stateQueryParameters (eg what tabs were open etc)
    this.$router.beforeEach((to, from, next) => {
      if (to.path !== from.path) {
        if (typeof unwatch === 'function') {
          unwatch();
        }
        unwatch = null;
        this.$store.commit('resetStateQueryParameters');
      }
      next();
    });

    this.$router.afterEach((to, from) => {
      if (to.path === from.path) {
        return;
      }
      this.parseQuery(to);
      unwatch = this.$watch('stateQueryParameters', this.updateUrlOnStateChange, {deep: true});
    });
  },
  mounted() {
    this.$root.$on('error', this.showError);
    const apiKey = this.privateQueryParameters.apikey || window.localStorage.getItem('apikey');
    if (apiKey) {
      this.$store.commit('setApiKey', apiKey);
    }
    setInterval(() => this.$store.dispatch('loadBackground', 3), 200);
  },
  methods: {
    parseQuery(route) {
      let privateFromHash = {};
      if (this.historyMode === 'history') {
        let uri = new URI(route.hash.replace(/^#/, ''));
        privateFromHash = uri.query(true);
      }
      let query = Object.assign({}, route.query, privateFromHash);
      let params = {};
      for(let key in query) {
        // Store all private query parameters (start with ~) and replace them in the shown URI
        if (key.startsWith('~')) {
          params.private = Utils.isObject(params.private) ? params.private : {};
          params.private[key.substr(1)] = query[key];
          delete query[key];
        }
        // Store all state related parameters (start with .)
        else if (key.startsWith('.')) {
          params.state = Utils.isObject(params.state) ? params.state : {};
          params.state[key.substr(1)] = query[key];
        }
        // All other parameters should be appended to the catalog requests
        else {
          params.request = Utils.isObject(params.request) ? params.request : {};
          params.request[key] = query[key];
        }
      }
      if (Utils.size(params) > 0) {
        this.$store.commit("queryParameters", params);
      }
      if (Utils.size(params.private) > 0) {
        this.$router.replace({
          ...route,
          query
        });
      }

    },
    updateUrlOnStateChange () {
      // Force vue-router to read some values as arrays
      for (const [key, value] of Object.entries(this.$route.query)) {
        if (Array.isArray(this.appStateAsParams[key]) && !(Array.isArray(value))) {
          this.$route.query[key] = [value];
        }
      }

      this.$router.replace({ query: this.appStateAsParams }).catch(error => {
        if (!VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.duplicated)) {
          throw Error(error);
        }
      });
    },
    showError(error, message) {
      this.$store.commit('showGlobalError', {
        error, 
        message
      });
    },
    hideError() {
      this.$store.commit('showGlobalError', null);
    }
  }
};
</script>

<style lang="scss">
@import "./theme/variables.scss";
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
@import "./theme/page.scss";
@import "./theme/custom.scss";
@import "./theme/assets/font/fonts.css";

* {
  font-family: "Montserrat" !important;
}

h1{
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
  margin-bottom: 8px;
  color: #13264B;
}

#stac-browser{
  padding: 0;
  height: 100%;
}

a {
  color: #3A6CCE;

  &:hover {
    color: #3A6CCE;
  }
}

/*.stac-container {
  padding: 0 370px;
  margin: 0 auto;
  max-width: 1920px;
}*/

/*.popover-header {
  height: 52px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #13264B;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popover-body {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #13264B;
  padding: 14px 15px 0 15px;
}*/

.b-sidebar-header {
  display: none !important;
  padding: 40px 40px 16px 35px !important;
}

.b-sidebar-header strong {
  //padding: 40px 40px 12px 40px !important;
  font-weight: 700;
  font-size: 27px !important;
  line-height: 33px;
  color: #13264B;
}

.b-sidebar-body {
  padding: 0px 40px 12px 35px !important;
  @media (max-width: 767px) {
    padding: 0 15px !important;
  }
}

.site-container {
  margin-top: 160px;
  @media (max-width: 1279px) {
    margin-top: 140px;
  }
  @media (max-width: 767px) {
    margin-top: 120px;
  }
}

.badge {
  min-height: 25px;
  max-height: 25px;
  background: #C9C9C9;
  gap: 8px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px !important;
  color: #FFFFFF;
  margin-bottom: 0 !important;
  align-items: center !important;

  .b-form-tag-remove {
    width: 12px;
    height: 12px;
    font-size: 0px !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M6.6933 6.50001L10.2089 2.30939C10.2679 2.23975 10.2183 2.13394 10.1272 2.13394H9.05848C8.99553 2.13394 8.93526 2.16207 8.89375 2.21028L5.99419 5.66698L3.09464 2.21028C3.05446 2.16207 2.99419 2.13394 2.92991 2.13394H1.86116C1.77009 2.13394 1.72053 2.23975 1.77946 2.30939L5.29509 6.50001L1.77946 10.6906C1.76626 10.7062 1.75779 10.7251 1.75506 10.7453C1.75233 10.7655 1.75545 10.7861 1.76405 10.8046C1.77265 10.823 1.78637 10.8386 1.80359 10.8496C1.8208 10.8605 1.84078 10.8662 1.86116 10.8661H2.92991C2.99285 10.8661 3.05312 10.838 3.09464 10.7897L5.99419 7.33305L8.89375 10.7897C8.93392 10.838 8.99419 10.8661 9.05848 10.8661H10.1272C10.2183 10.8661 10.2679 10.7603 10.2089 10.6906L6.6933 6.50001Z' fill='white'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
  }

  @media (max-width: 767px) {
    font-size: 0px;
    width: 25px;
    padding: 4px 0px;
    &::first-letter {
      font-size: 14px;
    }
  }
  @media (max-width: 479px) {
    display: none;
  }

  &.provider {
    border: 1px solid rgba(32, 64, 128, 0.30);
    background-color: rgba(32, 64, 128, 0.20) !important;
    color: #204080 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 3px 4px;
  }
  &.shown {
    border: 1px solid #345CAD;
    background: #3A6CCE;
    color: white;
    padding: 3px 4px;
  }
  &.role {
    border: 1px solid #6EA756;
    background: #9EC38E;
    color: white;
    padding: 3px 4px;
  }
  &.format {
    border: 1px solid #CA834F;
    background: #EB9D71;
    color: white;
    padding: 3px 4px;
  }
}

.card.asset {
  border: 1px solid #C9C9C9 !important;
  border-radius: 5px !important;
  background-color: white;
  min-height: 48px;
  width: 100%;
  margin: 0;
  .card-header {
    border: none;
  }
  .card-header button {
    background-color: white;
    min-height: 46px;
    border-radius: 10px !important;
    &:focus {
      box-shadow: none;
    }
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #13264B;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .metadata-card {
    margin-bottom: 0 !important;
  }
}

.card.recursed {
  width: 100% !important;
}

.leaflet-control-attribution {
  display: none;
}

.map-popover {
  border: none;
  border-radius: 8px;

  .popover-body {

    .header-popover {
      height: 24px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4.title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }

      .close-btn {
        width: 16px;
        height: 16px;
        background-image: url("theme/assets/img/close-sidebar.svg");
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    padding: 8px 12px 12px 12px;

    .item-card {
      margin-bottom: 0 !important;
    }

    .open-selected-item {
      width: 100%;
      height: 40px;
      margin-top: 8px;
      font-size: 13px;
      font-weight: 500;
      line-height: 19.5px;
      text-transform: uppercase;
      border-radius: 5px;
      background-color: #204080 !important;

      &:hover {
        box-shadow: 0 0 0 0.2rem rgb(32 64 128 / 20%);
      }
      &:active {
        box-shadow: 0 0 0 0.2rem rgb(32 64 128 / 70%) !important;
        border-color: #204080 !important;
      }
      &:focus {
        box-shadow: none;
        border-color: #204080 !important;
      }
    }
  }
}
</style>